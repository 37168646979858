import { ICanvasError, IEventOptions, IPageLaunchParams, IRecordChangedEvent} from "./types";
import React  from 'react';
import { RenderEvent } from "./RenderEvent";
import { Canvas } from "./Canvas";
import { ActionRef } from "./ActionRef";
import { DynamicRender } from "./DynamicRender";
import { IBotInfo } from "./BotState";
import { Application, ContextMenuHandler, ContextMenuHandlerArgs } from "core";
import { Popup } from "components/popups/Popup";
import { LoadingDots } from "components/helpers/LoadingDots";
import { StudioToolboxLaunch } from "components/studio/StudioToolboxLaunch";
import { DragImage, DropBar } from "./DragState";
import { AnchorPoint } from "components/helpers/AnchorPoint";
import { PageContextMenu } from "components/PageContextMenu";
import { StackLayerContainer } from "components/helpers/StackLayerContainer";

interface IPageContainerProps {
    page:IPageLaunchParams;
    parent?:Canvas,
    layer?:any,
    layerZIndex?:number,
    botInfo?:IBotInfo,
    autoRefresh?:number;
    canvas?:Canvas;
    onClose?:any,
    handleRecordChanged?:(recordEvent:IRecordChangedEvent) => Promise<void>,
    eventValue?:any,
    event?:RenderEvent,
    onLoaded?:(canvas:Canvas) => void,
    noPadding?:boolean;
    asWidget?:boolean;
    unableToLoadContent?:() => any
}

export class PageContainer extends React.Component<IPageContainerProps>{

    canvas:Canvas;
    pageLoadError:any;

    constructor(props){
        super(props);
        if (this.props.canvas){
            this.canvas = this.props.canvas;
            return;
        }

        let parent = this.props.parent;
        let renderEvent = this.props.event;
        if (!parent){
            if (renderEvent){
                parent = renderEvent.canvas;
            }
        }
        this.canvas = new Canvas(parent);
        if (renderEvent){
            this.canvas.visibiltyContainer = renderEvent.visibilityContainer;
        }
        if (this.props.autoRefresh){
            this.canvas.setAutoRefresh(this.props.autoRefresh);
        }
        this.canvas.botInfo = this.props.botInfo;
        this.canvas.launchParams = this.props.page;
        let settings = Application.instance.canvasSettingsCache.getSettings(this.props.page.name);
        this.canvas.loadSettings(settings);
        if (this.canvas.launchParams.name == "pages/Dev/Components.TestRecordFunction:default"){
            if (window.parent){
                this.canvas.studioSource = (window.parent as any)["playgroundSource"];
            }
        }
    }

    async componentDidMount(){
        let canvas = this.canvas;
        canvas.app.registerScreen(this);
        canvas.closeDialogBox = this.props.onClose;
        if (!this.props.canvas){
            
            canvas.layer = this.props.layer;
            if (!canvas.layer){
                if (canvas.parent){
                    if (canvas.parent.layer == "workspace"){
                        canvas.layer = "workspace-tab";
                    }
                    else {
                        canvas.layer = "embedded";
                    }
                }
                else {
                    canvas.layer = "layout";
                }
            }
            canvas.layerZIndex = this.props.layerZIndex;
           
            canvas.handleRecordChanged = this.props.handleRecordChanged;
            canvas.noPadding = this.props.noPadding;
            canvas.asWidget = this.props.asWidget;
            await this.start(canvas,false);
        }

    
        // todo: if waiting for data hydrate
        // botState.waitingForDataHydrate(canvas); // if data already received populate
        /*
        if (canvas.botInfo && canvas.botInfo.onLoaded){
            canvas.botInfo.onLoaded();
        }
        */
    }

  
    componentWillUnmount(){
        this.canvas.updateComponent = null;
        this.canvas.app.unregisterScreen(this);
    }

    render(){
        let canvas = this.canvas;
        if (canvas.renderDisabled) return null;
        if (canvas.loadError && this.props.unableToLoadContent){
            return this.props.unableToLoadContent();
        }
        if (canvas.loadError && (canvas.layer == "dialog" || canvas.layer == "lookup")){
            /*
            return <div style={{position:"absolute",top:0,left:0,right:0,bottom:0,display:"flex",justifyContent:"center",alignItems:'center'}}>
                <div style={{backgroundColor:"#fff",padding:50}}>
                    <div>Screen could not be loaded</div>
                    <div style={{marginTop:30,textAlign:"center"}}>
                        <button onClick={()=> canvas.closeDialogBox({continue:false})}>Close</button>
                    </div>
                </div>
            </div>
            */
            let msg:any = <><span style={{fontWeight:400}}>Could not open screen: </span>{this.props.page.name}</>;
            let error:ICanvasError = {$$error:true,messages:[msg]};
            let dialog = canvas.app.renderError(canvas,error);
            dialog = React.cloneElement(dialog,{layer:"dialog",onClose:() => canvas.closeDialogBox({continue:false})});
            return dialog;
        }
        return <CanvasContainer canvas={canvas} page={this.props.page}/>
    }

    async start(canvas:Canvas,isCachedVersionRefresh:boolean){
        
        canvas.app.spinner.show();
        let action = new ActionRef(canvas,"begin",null);
        try {
            let options:IEventOptions = {value:this.props.eventValue,title:"Attempting to load screen: " + this.props.page.name};
            options.isCachedVersionRefresh = isCachedVersionRefresh;
            canvas.initialized = false;
            let result = await canvas.triggerAction(action,options);
            if (!result.continue){
                // start failed;  
                
                canvas.loadError = "Unable to load screen: " + this.props.page.name;
                this.forceUpdate();
                return;
            }
        }
        catch(e){
            canvas.app.spinner.hide();
            canvas.initialized = false;
            if (e == "stopped"){
                if (canvas.closeDialogBox){
                    canvas.closeDialogBox({continue:false});
                }
                else {
                    canvas.renderDisabled = true;
                }
            }
            else {
                canvas.loadError = e.toString();
            }
            this.forceUpdate();
            canvas.update();
            return;
        }
        canvas.app.spinner.hide();
        
        // todo: if waiting for data hydration then not loaded
        if (canvas.botInfo && canvas.botInfo.dataRequestId){
            canvas.botInfo.botState.waitingForData(canvas);
        }
        else {
            if (!canvas.displayContent && !canvas.htmlContent){
                if (canvas.closeDialogBox){
                    canvas.closeDialogBox({continue:true});
                }
                return;
            }
            canvas.loaded();
            if (canvas.layer == "workspace"){
                let suffix = canvas.app.portalTitle || "Portal";
                if (canvas.title){
                    document.title = canvas.title + " - " + suffix
                }
                else {
                    document.title = suffix;
                }
            }
            if (canvas.parent){
                canvas.parent.onChildLoaded(canvas);
            }
          
          //  canvas.pendingFocusField = undefined;
            if (canvas.botInfo && canvas.botInfo.onLoaded){
                canvas.botInfo.onLoaded();
            }
            else if (canvas.layer == "popup"){
                canvas.update();
                canvas.parent.update();
            }
            else {
                canvas.update();
            } 
            if (this.props.onLoaded){
                this.props.onLoaded(canvas);
            }
        }
        if (!isCachedVersionRefresh && canvas.loadedFromCache){
            if (!this.insideLoadedFromCache(canvas)){
                setTimeout(()=> this.start(canvas,true),10);
            }
        }
    }
    
    insideLoadedFromCache(canvas:Canvas):boolean {
        while(canvas.parent){
            if (canvas.parent.loadedFromCache) return true;
            canvas = canvas.parent;
        }
        return false;
    }
    async reload(){
        this.canvas.resetData();
        await this.start(this.canvas,false);
    }
}

class CanvasContainer extends React.Component<{canvas:Canvas,page:IPageLaunchParams}>{

    contextMenuHandler:ContextMenuHandler;
    
    constructor(props){
        super(props);
        let canvas = this.props.canvas;
        this.contextMenuHandler = canvas.app.contextMenuManager.createHandler(canvas,this.handleContextMenuClick);
        canvas.contextMenuId = this.contextMenuHandler.id;
    }

    componentDidMount(){
        let canvas = this.props.canvas;
        canvas.app.contextMenuManager.connect(this.contextMenuHandler);
        canvas.app.studioBroadcast.connect(this);
        canvas.updateComponent = () => {
            this.forceUpdate();
        }
    }

    componentWillUnmount(){       
        let canvas = this.props.canvas;
        canvas.disconnect();
        if (this.contextMenuHandler){
            canvas.app.contextMenuManager.disconnect(this.contextMenuHandler);
        }
        canvas.app.studioBroadcast.disconnect(this);
    }

    componentDidUpdate(prevProps: Readonly<{ canvas: Canvas;page:IPageLaunchParams}>, prevState: Readonly<{}>, snapshot?: any): void {
        let canvas = this.props.canvas;
        if (canvas.pendingRestart && !canvas.getIsStackCovered() ){
            canvas.pendingRestart = false;
            canvas.restart();
        }
    }

    render(){
        let canvas = this.props.canvas;
        if (!canvas.initialized) return <LoadingDots />

        let displayContent = canvas.displayContent;
        if (displayContent){
            let renderEvent = new RenderEvent(canvas);
            let contextMenu;
            if (this.contextMenuHandler && this.contextMenuHandler.isOpen){
                contextMenu = this.renderContextMenu();
            }
            let popup;
            if (canvas.activePopup){
                popup = this.renderActivePopup();
            }
            let designerElem;
            if (canvas.designerOpen ){ //&& canvas.dragState
                designerElem = <>
                    <div key="launch" style={{position:"absolute",bottom:15,left:15,zIndex:50000}}>
                        <StudioToolboxLaunch key="studio-launch" event={renderEvent}/>
                    </div>
                    <DropBar key="dropBar" dragState={canvas.dragState} />
                    <DragImage key="dragImage" dragState={canvas.dragState} />
                </>
            }
            return <RefreshableContentWrapper key={canvas.hardRefreshKey}>
                {DynamicRender.render(renderEvent,displayContent)}
                {contextMenu}
                {popup}
                {designerElem}
               
            </RefreshableContentWrapper>
        }
        else if (canvas.htmlContent){
            return <RefreshableContentWrapper key={canvas.hardRefreshKey}>
                <HtmxContentWrapper canvas={canvas} html={canvas.htmlContent}/>
            </RefreshableContentWrapper>
        }
        return null;

    }

    handleContextMenuClick = (args:ContextMenuHandlerArgs) => {

    
        this.contextMenuHandler.rect = args.rect;
        let elem = args.target.closest('[data-elem-id]');
        if (elem){
            this.contextMenuHandler.elemId = elem.getAttribute("data-elem-id");
        }
        else {
            this.contextMenuHandler.elemId = null;
        }
        this.contextMenuHandler.open();
      
    }

    renderContextMenu(){
        
        let handler = this.contextMenuHandler;
        return (<Popup
            key="context-menu"
            attachedRef={handler.rect}
            anchorPoint={AnchorPoint.BottomAlignLeft}
            onForceClose={this.handlePopupForceClose}
            onWheel={null}
            onAnchored={null}
            zIndex={2990}
            render={this.renderMenu}
            withCover
        />);

        

    }

    renderActivePopup(){
        let popup = this.props.canvas.activePopup;
        return (<Popup
            key="active-popup"
            attachedRef={popup.rect}
            anchorPoint={AnchorPoint.BottomAlignLeft}
            onForceClose={this.handlePopupForceClose}
            onWheel={null}
            onAnchored={null}
            zIndex={2990}
            render={this.renderActivePopupContent}
            withCover
        />);
    }

    renderActivePopupContent = (options:{onResize:()=> void}) => {
        return <div className="shadow-xl studio" style={{border:"solid 3px rgb(232,232,232)",borderRadius:8,
            maxWidth:600,backgroundColor:"#fff",
            display:'flex',flexDirection:'column',minHeight:100}}>
            {this.props.canvas.activePopup.render(options)}
        </div>
    }
    renderMenu = (options:{onResize:() => void})=> {
        let canvas = this.props.canvas;
        return <PageContextMenu page={canvas.pageId} onClose={this.handlePopupForceClose as any}
            pageParams={canvas.launchParams.props} onLoaded={options.onResize}
        />
    
    }

    handlePopupForceClose = (target:HTMLElement,isTop:boolean) => {
        this.contextMenuHandler.isOpen = false;
        this.props.canvas.activePopup = null;
        this.forceUpdate();
    }

   
    
}



const RefreshableContentWrapper = ({children}) => children || null;

class HtmxContentWrapper extends React.Component<{canvas:Canvas,html:string}>{
    ref:React.RefObject<HTMLDivElement> = React.createRef();
    
    componentDidMount(): void {
        htmx.process(this.ref.current);
        let pageContainer = this.ref.current.querySelector('page-container');
        if (pageContainer){
            let canvas = this.props.canvas;
            canvas.id = pageContainer.getAttribute('id');
            canvas.pageId = pageContainer.getAttribute("s4s:page.name");
           
            let input = this.ref.current.querySelector('input');
            if (input){
                input.select();
                input.focus();
            }
            lisa.showFrames(pageContainer);
        }
    
    }

    render(){
        let canvas = this.props.canvas;
        let stackContent;
        if (canvas.stackChild){
            stackContent = canvas.stackChild();
        }
        let body = <div className="s4s-canvas" ref={this.ref} dangerouslySetInnerHTML={{__html:this.props.html}}/>;
        if (canvas.layer == "dialog"){
            return body;
        }
        return <StackLayerContainer id="" canvas={this.props.canvas} containerClass="stackable-s4s" body={body} stackContent={stackContent} converedTitle={null}/>
    }
}

