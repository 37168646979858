
import { ScreenRef } from "./ActionEvent";
import {executeCommands,processErrors} from './StatementRunner';
import { executeConfig } from "./ConfigurationRunner";
import { IRequestCallType } from "./Request";
import { IRecordSchema } from "./Schema";
import { IActionDefinition, IActionResult, ICanvasError, ICodeError } from "./types";
import { PageForbiddenError } from "./errors/PageForbiddenError";

export interface IServiceResponse {
    actions:{[name:string]:IActionDefinition};
    schema:IRecordSchema;
    run:any[];
    configure:any[];
    errorTable:any[];
    success:boolean;
    retryAfterWarnings:boolean;
    debug:any;
    error:any;
    code:any;
    codeErrors:ICodeError[];
    codeHTML:string;
    codeSource:any[];
    returnValue:any;
    pageErrorCode?:string;
    errorRender?:any;
    pageId?:string;
    pageInfo:{
        page:string;
        version:string;
        draft_id:string;
    }
    source?:any;
    bot?:{
        command:string;
        timestamp:string;
        data_request_id:string;
    }
    contentHTML?:string;
}

export class Response {
    static async process(screen:ScreenRef,callType:IRequestCallType, res:IServiceResponse):Promise<IActionResult>{

        let currentRowKeys;

       
        if (callType == "start" || callType == "restart"){
            currentRowKeys = screen.canvas.getCurrentRowKeys();
            await Response.loadStartValues(screen,res);
        }
        else {
            if (res.pageInfo) {
                screen.canvas.pageInfo = res.pageInfo;
            }
        }
        if (res.error){
            throw res.error.message || "Request failed";
        }
        if (res.errorTable && res.errorTable.length){
            let messages:string [] = [];
            for (let i =0 ; i < res.errorTable.length;i++){
                let item = res.errorTable[i];
                messages.push(item.message);
            }
            if (!messages.length){
                messages.push("Unknown API Error");
            } 
            
            if (res.pageErrorCode == "PAGE_FORBIDDEN"){
                throw new PageForbiddenError(messages,res.errorRender);
            }    
            let error:ICanvasError = {
                messages,
                $$error:true,
                sourceCode:res.codeSource,
                codeErrors:res.codeErrors,
                render:res.errorRender
            }    
            throw error;
        }
        let run = res.run;
        if (run){
        
            await executeCommands(screen,run);
            let error =  processErrors(screen,run,res.errorRender);
            if (error){
                if (error.showDialog){
                    throw error;
                }
                throw "stopped";
            }
            
        }
       // screen.returnValue = res.returnValue;
        if (callType == "start"){
            screen.canvas.restoreCurrentRowKeys(currentRowKeys);
        }
        return {continue:true};
    }

    static async loadStartValues(screen:ScreenRef,res:IServiceResponse){
        let canvas = screen.canvas;
     
        
      
        if (res.configure){
            await executeConfig(screen,res.configure);
        }
        if (res.actions){
            canvas.actions = res.actions;
        }
        if (res.pageId){
            canvas.loadStartValues();
            canvas.pageId = res.pageId;
        }
        if (res.schema){   
            canvas.setSchema(res.schema);
        }
        if (res.pageInfo){
            canvas.pageInfo = res.pageInfo;
        }
        
        if (canvas.botInfo && res.bot){
            canvas.botInfo.command = res.bot.command;
            canvas.botInfo.message.timestamp = res.bot.timestamp;
            canvas.botInfo.dataRequestId = res.bot.data_request_id;
        }
        if (res.contentHTML){
            canvas.htmlContent = res.contentHTML;
        }

        
    }
}
