import { Icon } from 'components/Icon';
import { Canvas } from 'core';
import { CanvasStackPosition } from 'core/Canvas';
import React from 'react';
import { HorizontalSizer } from './HorizontalSizer';
import { ScrollRegion } from './ScrollRegion';

export interface ISidebarProps {
    fixedOpen?:boolean;
    overlayBody?:boolean;
    width?:any;
    children?:any;
}

export interface INavbarProps {
    collapsible?:boolean;
    overlayBody?:boolean;
    width?:any;
    children?:any;
}
const defaultPageBackgroundColor = "var(--rt-page-bg-color)";

export class StackLayerContainer extends React.Component<{id:string;canvas: Canvas,scrollable?:boolean;header?:any,body:any,footer?:any,sidebar?:any,
    converedTitle:any;stackContent:any,containerClass?:string,
    navigator?:any,bodyClass?:string;headerClass?:string;sidebarClass?:string,footerClass?:string;
    navbar?:any,navbarProps?:INavbarProps,navbarClass?:string,contextMenuId?:string,pageType?:string}>{
   
    containerRef:React.RefObject<HTMLDivElement> = React.createRef();
    
    componentDidMount(){
        let canvas = this.props.canvas;
        this.props.canvas.getContainerWidth = this.getContainerWidth;
       
    }

    componentWillUnmount(){
        this.props.canvas.getContainerWidth = null;
    }

    componentDidUpdate(){
        if (!this.props.stackContent) return;
        let canvas = this.props.canvas;
        let width = this.getContainerWidth();
        if (width < 960 && canvas.stackPositionOverride != "top"){
            canvas.stackPositionOverride = "top";
            this.forceUpdate();
        }
    }

    getPageBackgroundColor():string {
        return defaultPageBackgroundColor;
    }

    render(){
        let body = this.props.body;
        let canvas = this.props.canvas;
        let stackContent = this.props.stackContent;
        let clickHandler;
        let opacity = 1;
        let bodyDisplay = "block";
        let covered = false;
        let stackPosition = canvas.stackPositionOverride || canvas.stackPosition || "overlay";
        
        if (stackContent && stackPosition == "top"){
            covered = true;
        }
      
        if (covered) {
            clickHandler = this.handleCloseStack;
            opacity = 0.6;
            bodyDisplay = "none";
            covered = true;
        }
       

        let headerElem;
        let stack;
       
        let headerClass = "RT-Stackable-Container__header ";
        if (this.props.headerClass){
            headerClass += this.props.headerClass;
        }

        let bodyClass = "RT-Stackable-Container__body ";
        if (this.props.bodyClass){
            bodyClass +=  this.props.bodyClass;
        }
        if (this.props.header){
            headerElem = <div key="header" className={headerClass}>
                {this.props.header}
            </div>
        }
    
        let sizer;
        let bodyStyle:React.CSSProperties = {zIndex:5};
        if (canvas.layer == "dialog" || canvas.layer == "dialog-stack"){
            bodyStyle.backgroundColor = "#f4f6f8";
            bodyStyle.borderBottomLeftRadius = 5;
            bodyStyle.borderBottomRightRadius = 5;
        }
        if (covered){
            let stackClass = "RT-Stackable-Container__stack RT-Stackable-Container--" + canvas.layer;
           
            stack = <div key="stack-wrapper" style={{position:'absolute',top:0,left:0,right:0,bottom:0}}>
                <div key="stack-content" className={stackClass} style={{position:"relative",zIndex:20}} >
                    {this.renderCoveredHeader(this.props.converedTitle,this.props.navigator)} 
                    <div key="stack-body" className="RT-Stackable-Container__stack-body" style={bodyStyle}>
                        {stackContent}
                    </div>
                </div>
            </div>
        }
        else if (stackContent){
            
            let peekWidth = canvas.stackOverlayOffsetX;
            let stackClass = "RT-Stackable-Container__stack RT-Stackable-Container--" + canvas.layer;
            let colorBlock;
            let backgroundColor = this.getPageBackgroundColor();
            if (canvas.layer == "stack"){
                colorBlock =  <div style={{backgroundColor,bottom:0,zIndex:1,position:'absolute',top:0,left:0,width:5}}/>
            }
            sizer = <HorizontalSizer left={peekWidth} width={6} setSize={this.setOverlaySize} />
            let closeButtonBackgroundColor = (this.props.pageType == "help") ? "#fff" : defaultPageBackgroundColor;
            stack = <div key="stack-wrapper"  className="shadow-xl" style={{zIndex:50,position:"absolute",top:0,right:0,bottom:0,left:peekWidth,backgroundColor:this.getPageBackgroundColor()}}>
                    <OverlayCloseButton onClick={this.handleCloseStack} canvas={canvas} stackPosition={stackPosition} backgroundColor={closeButtonBackgroundColor} containerClass={this.props.containerClass}/>
                <div key="stack-content" className={stackClass}>              
                    <div key="stack-body" className="RT-Stackable-Container__stack-body" style={bodyStyle}>
                        {stackContent}
                    </div>
                    {colorBlock} 
                </div>
                
            </div>
        }
        let sidebarProps:ISidebarProps;
        if (this.props.sidebar){
            sidebarProps = this.props.sidebar.props;
        }

        let navbarProps:INavbarProps = this.props.navbarProps;
      
        let outerStyle:React.CSSProperties = {};
        
    
        let className="RT-Stackable-Container";
        className += " RT-Stackable-Container--" + canvas.layer;
        if (this.props.containerClass){
            className += " " + this.props.containerClass;
        }
        
        if (this.props.scrollable){
            className += " RT-Stackable-Container--scrollable";
            bodyClass += " RT-Stackable-Container--scrollable";
        }
        if (covered){
            className += " RT-Stackable-Container--covered";
            outerStyle.height = 0;
        }
        else if (stackContent){
            outerStyle.opacity = 0.80;
        }
        let sidebarOpen:boolean;
        let sidebarWidth:any;
        if (sidebarProps && sidebarProps.children && sidebarProps.children.length){
            sidebarOpen = canvas.sidebarState && canvas.sidebarState.isOpen;
            sidebarWidth = sidebarProps.width || 400;
            if (sidebarProps.fixedOpen){
                sidebarOpen = true;
            }
        }

        let navbarOpen:boolean;
        let navbarWidth:any;
        if (navbarProps){
            navbarOpen = !canvas.navbarState || !canvas.navbarState.isCollapsed;
            navbarWidth = navbarProps.width || 240;
        }
    
        /*
        if (stackContent && stackLayer == "preview"){
            previewContent = <div className="RT-Stackable-Container__preview">
                {stackContent}
            </div>
        }
        */
        let sidebarContent;
        if (sidebarOpen){
            let sidebarStyle:React.CSSProperties = {width:sidebarWidth,position:"relative",zIndex:20};
            if (canvas.layer == "dialog" || canvas.layer == "dialog-stack" || canvas.layer == "popup"){
                sidebarStyle.backgroundColor = "transparent";
            }
            if (sidebarProps.overlayBody){
                sidebarStyle.boxShadow = " 0 4px 4px rgb(100 110 120 / 40%)";
            }
            let closeButton;
            if (!sidebarProps.fixedOpen){
                closeButton = (
                    <div className="RT-Stackable-Container__close-sidebar-button" onClick={this.handleCloseSidebar}>
                        {this.renderCloseSidebar()}
                    </div>
                );
            }
            sidebarContent = <div className="RT-Stackable-Container__sidebar" style={sidebarStyle}>
                {this.props.sidebar}
                {closeButton}
            </div>
        }
        else if (sidebarProps && sidebarProps.children && sidebarProps.children.length){
            sidebarContent = <div className="RT-Stackable-Container__open-sidebar-button" onClick={this.handleOpenSidebar}>{this.renderOpenSidebar()}</div>
        }

        let navbarContent;
        if (navbarOpen){
            let navbarStyle:React.CSSProperties = {width:navbarWidth,overflowY:"auto",height:"100%"};
            if (navbarProps.overlayBody){
                navbarStyle.boxShadow = " 0 4px 4px rgb(100 110 120 / 40%)";
            }
            let closeButton;
            if (navbarProps.collapsible){
                closeButton = (
                    <div className="RT-Stackable-Container__close-sidebar-button" onClick={this.handleCloseNavbar}>
                        {this.renderCloseSidebar()}
                    </div>
                );
            }
            navbarContent = <div style={navbarStyle} className="rt-scrollbars">
                <div className="RT-Stackable-Container__side-nav" style={{paddingLeft:30,paddingBottom:30,paddingTop:10,display:"flex",flexDirection:"column",rowGap:10}}>
                    {this.props.navbar}
                </div>
                {closeButton}
            </div>
        }
        else if (navbarProps){
            navbarContent = (<div className="RT-Stackable-Container__open-sidebar-button" onClick={this.handleOpenNavbar}>
                {this.renderOpenSidebar()}
            </div>)
        }


        let mainStyle:React.CSSProperties = {zIndex:2};
        if (canvas.layer == "stack"){
            mainStyle.backgroundColor = this.getPageBackgroundColor();
            mainStyle.minHeight = 100;
        }
        
        if ((canvas.layer == "workspace-tab" || canvas.layer == "stack") && canvas.width){
            if (canvas.width == "small"){
                mainStyle.maxWidth = 600;
            //    mainStyle.marginLeft = 'auto';
            //    mainStyle.marginRight = 'auto';
            }
            else if (canvas.width == "medium"){
                mainStyle.maxWidth = 900;
                mainStyle.marginLeft = 'auto';
                mainStyle.marginRight = 'auto';
            }
            else if (canvas.width == "large"){
                mainStyle.maxWidth = 1240;
                mainStyle.marginLeft = 'auto';
                mainStyle.marginRight = 'auto';
            }
            else if (canvas.width == "x-large"){
                mainStyle.maxWidth = 1560;
                mainStyle.marginLeft = 'auto';
                mainStyle.marginRight = 'auto';
            }
        }
       
        if (canvas.layer == "embedded"){
           // outerStyle.padding = "15px 30px 0 30px";
        }
        let footerElem;

        if (this.props.footer){
            let footerClass = "RT-Stackable-Container__footer";
            if (this.props.footerClass){
                footerClass += " " + this.props.footerClass;
            }
            footerElem = <div className={footerClass} style={{display:bodyDisplay,position:"sticky",bottom:0,backgroundColor:"var(--rt-page-bg-color)"}}>{this.props.footer}</div>
        }
        let mainContent;

        if (navbarContent){
            mainContent = (<>
                {headerElem}
                <div className={bodyClass} style={{display:bodyDisplay}}>
                    {body}
                </div>
                {footerElem}
            </>);
        }
        else {
            mainContent = (<>
                {headerElem}
                <div className={bodyClass} style={{display:bodyDisplay}}>
                    {body}
                </div>
                {footerElem}
            </>);
        }
        if (this.props.scrollable){
            mainContent = <ScrollRegion className="RT-Stackable-Container__scroll" style={{display:"flex",flexDirection:'column'}}>{mainContent}</ScrollRegion>
        }
    
        if (stackContent && stackPosition == "overlay"){
           
            mainStyle.marginLeft = 0 ;
            mainStyle.marginRight = 0;
        }
        let sidebarElem;
        if (sidebarContent){
            let sidebarClass = this.props.sidebarClass;
            let sidebarStyle:React.CSSProperties;
            if (sidebarProps.overlayBody){
                sidebarStyle = {position:"absolute",top:0,left:0,right:0,bottom:0,zIndex:5};
            }
            
            sidebarElem = ( <div style={sidebarStyle} className={sidebarClass}>
                {sidebarContent}
            </div>)
        }

        let navbarElem;
        if (navbarContent){
            let navbarClass = this.props.navbarClass;
            let navbarStyle:React.CSSProperties;
            if (navbarProps.overlayBody){
                navbarStyle = {position:"absolute",top:0,left:0,right:0,bottom:0,zIndex:5};
            }
            
            navbarElem = ( <div style={navbarStyle} className={navbarClass}>
                {navbarContent}
            </div>)
        }
        let draftInfo:any;
        if (canvas.pageInfo.draft_id && !canvas.renderDesignSlot){
            let style:React.CSSProperties = {paddingLeft:25,paddingTop:2,display:"flex",alignItems:"center"};
            if (canvas.pageType == "help"){
                style.backgroundColor = "#fff";
            }
            draftInfo = <div style={style}>
                <div className="bg-orange-200 text-orange-900 border-1 border-orange-300" style={{padding:"1px 8px",borderRadius:4,fontSize:"13px",fontWeight:500}}>Draft</div>
            </div>
        }
       
        return <>
            <div key="content" ref={this.containerRef} data-elem-id={this.props.id} data-context-menu={this.props.contextMenuId} className={className} style={outerStyle}>
                {navbarElem}
                <div className="RT-Stackable-Container__main" style={mainStyle}>
                    {draftInfo}
                    {mainContent}
                </div>
                {sidebarElem}
            </div>
            {stack}
            {sizer}
        </>
    }

    setOverlaySize = (adjustX:number) => {
        let canvas = this.props.canvas;
        let newOffset = canvas.stackOverlayOffsetX + adjustX;
        let containerWidth = this.containerRef.current.offsetWidth;
        if (newOffset > containerWidth - 300){
            newOffset = containerWidth - 300;
        }
        if (newOffset < 80){
            newOffset = 80;
        }

        canvas.stackOverlayOffsetX = newOffset;
        canvas.app.canvasSettingsCache.setOverlayWidth(canvas.launchParams.name,newOffset);
        canvas.update();
    }

    getContainerWidth = ():number =>{
        if (this.containerRef.current){
            return this.containerRef.current.offsetWidth;
        }
    }

    renderCoveredHeader(title:any,navigator:any){
        let nav;
    
        nav =  (<div className="RT-Stackable-Container__stack-header-nav">
            {navigator}
        </div>);
    
        let style:React.CSSProperties;
        let canvas = this.props.canvas;
       
        if (canvas.layer == "dialog" || canvas.layer == "dialog-stack" || canvas.layer == "popup"){
            style = {backgroundColor:"#fff",opacity:1,borderTopLeftRadius:5,borderTopRightRadius:5,padding:"0 35px"};
        }
        else if (canvas.layer != "workspace"){
            style = {backgroundColor:this.getPageBackgroundColor()};
        }
        let closeButton;
        if ((canvas.layer == "dialog" && canvas.showClose) || canvas.layer == "popup"){
            closeButton = <div onClick={this.handleClickClose} className="hover-opacity-20" style={{position:"absolute",right:15,top:0,bottom:0,display:'flex',alignItems:'center',cursor:"pointer"}}>
                <Icon icon="close" size={26} />
            </div>
        }
        return (<div key="breadcrumb" style={style} className="RT-Stackable-Container__stack-header" onClick={this.handleCloseStack}>
                {title}
                <div style={{height:1,position:"absolute",bottom:0,left:10,right:10,backgroundColor:"rgb(212,214,220)"}}/>
                {nav}
                {closeButton}
        </div>);
    }
    
    

    handleOpenSidebar = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.canvas.sidebarState.isOpen = true;
        this.forceUpdate();
    }

    handleCloseSidebar = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.canvas.sidebarState.isOpen = false;
        this.forceUpdate();

    }

    handleOpenNavbar = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.canvas.navbarState.isCollapsed = false;
        this.forceUpdate();
    }

    handleCloseNavbar = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.canvas.navbarState.isCollapsed = true;
        this.forceUpdate();

    }
    handleCloseStack = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let canvas = this.props.canvas;
        canvas.closeStack();
        this.forceUpdate();
    }

    handleClickClose = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.canvas.closeDialogBox({continue:false});
    }
    renderOpenSidebar(){
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
          <path d="M17,11H5.41l2.3-2.29A1,1,0,1,0,6.29,7.29l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L5.41,13H17a1,1,0,0,0,0-2Zm4-7a1,1,0,0,0-1,1V19a1,1,0,0,0,2,0V5A1,1,0,0,0,21,4Z"/>
        </svg>);
    }

    renderCloseSidebar(){
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M17.71,11.29l-4-4a1,1,0,1,0-1.42,1.42L14.59,11H3a1,1,0,0,0,0,2H14.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76A1,1,0,0,0,17.71,11.29ZM21,4a1,1,0,0,0-1,1V19a1,1,0,0,0,2,0V5A1,1,0,0,0,21,4Z"/>
        </svg>);
    }

   
}

class OverlayCloseButton extends React.Component<{onClick,canvas:Canvas,stackPosition:CanvasStackPosition,backgroundColor:string;containerClass}>{
    render(){
        let goFull;
        let goOverlay;
        let canvas = this.props.canvas;
        if (canvas.layer == "workspace-tab" && this.props.stackPosition =='top'){
            goOverlay = ( <svg xmlns="http://www.w3.org/2000/svg" className="hover-opacity-20" onClick={this.handleClickOverlay} viewBox="0 0 24 24" style={{fill:"var(--rt-text-color)",width: 22,height: 22,display:'block',
            zIndex: 10}}><path d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"></path></svg>
            )
        }
        else {
            goFull = ( <svg xmlns="http://www.w3.org/2000/svg" className="hover-opacity-20" onClick={this.handleClickFull} viewBox="0 0 24 24" style={{fill:"var(--rt-text-color)",width: 22,height: 22,display:'block',
            zIndex: 10}}><path d="M8.5,12.8l5.7,5.6c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0c0.4-0.4,0.4-1,0-1.4l-4.9-5l4.9-5c0.4-0.4,0.4-1,0-1.4c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3l-5.7,5.6C8.1,11.7,8.1,12.3,8.5,12.8C8.5,12.7,8.5,12.7,8.5,12.8z"></path></svg>
            )
        }
        let className = ['RT-Stackable-Container__closetab'];
        if (this.props.containerClass){
            className.push(this.props.containerClass)
        }
        return (<div className={className.join(' ')} style={{position:"absolute",top:0,left:-26,borderTopLeftRadius:13,borderBottomLeftRadius:13,
            width:26,height:72,
            display:"flex",justifyContent:'center',gap:10,alignItems:'center',cursor:"pointer",flexDirection:'column',
            boxShadow:"0 2px 10px 0px rgb(0 0 0 / 10%), 0 4px 6px -4px rgb(0 0 0 / 10%)"}} >
               <svg xmlns="http://www.w3.org/2000/svg" onClick={this.props.onClick} className="hover-opacity-20" viewBox="0 0 24 24" style={{fill:"var(--rt-text-color)",width: 22,height: 22,display:'block',
                zIndex: 10}}><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path></svg>
               {goFull}
               {goOverlay}
        </div>)
    }

    handleClickFull = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let canvas = this.props.canvas;
        canvas.stackPositionOverride = "top";
        canvas.update();
    }

    handleClickOverlay = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let canvas = this.props.canvas;
        canvas.stackPositionOverride = "overlay";
        canvas.update();
    }
}


