import { PageContainer,RenderEvent } from 'core';
import { IPageLaunchParams } from 'core/types';

import React from 'react';
import { MainMenu } from './MainNavMenu';

export class Layout extends React.Component<{event:RenderEvent,header?:any,navstrip?:any}>{

    render(): React.ReactNode {

        let content;
        let event = this.props.event;
        let app = event.canvas.app;
        if (app.currentWorkspace){
            let page:IPageLaunchParams = {name:app.currentWorkspace};
            let unableToLoadContent  = ()=> (<div style={{padding:30,backgroundColor:"#fff",margin:30}}>
                <MainMenu app={app} />
            </div>);
            content = <PageContainer key="content" layer="workspace" page={page} unableToLoadContent={unableToLoadContent} />
        }
        let navStrip;
        let contentLeft = 0;
        if (this.props.navstrip){
            navStrip = ( <div style={{position:"absolute",top:0,left:0,width:66,bottom:0,zIndex:10}}>
                {this.props.navstrip}
            </div>);
            contentLeft = 66;
        }
        let header;
        if (!app.studioOpen){
            header = this.props.header;
        }
        return (<>
            {navStrip}
            <div style={{position:"absolute",left:contentLeft,top:0,right:0,bottom:0,display:"flex",flexDirection:"column"}}>
                {header}
                <div key={app.workspaceKey} style={{flexGrow:1,position:"relative",zIndex:20}}>
                    {content}
                </div>
            </div>   
           
        </>)
    }
}


export class LayoutHeader extends React.Component<{event:RenderEvent}>{
    render(): React.ReactNode {
        return <div>{this.props.children}</div>
    }
}