import {  Dialog, DialogResult, ErrorDialog, Stack } from './Dialog';
import { ActionEvent } from './ActionEvent';
import {ActionHandler,IEventOptions,SidebarState,IEventScope, IActionResult,  IActionDefinition, 
    IPageLaunchParams, IVisibilityContainer, IRecordChangedEvent, RecordChangedHandler, NavbarState} from './types';
import { FieldRef } from './FieldRef';
import { AppDataRecord, Application } from './Application';
import { RecordBrowse } from './RecordBrowse';
import { RecordMeta } from './RecordMeta';
import { ActionRef } from './ActionRef';
import { IRecordSchema, IScreenParameter, Schema } from './Schema';
import { Broadcast, CollectionRef, ICanvasError, PageContainer, RenderEvent } from '.';
import { Collection } from './Collection';
import {executeCommands} from './StatementRunner';
import { Evaluator } from './expressions/Evaluator';
import React from 'react';
import {IRequestCallType, Request} from './Request';
import { AutoRefreshSettings } from './AutoRefreshSettings';
import { IBotInfo } from './BotState';
import { CanvasSettings } from './CanvasSettingsCache';
import { PageForbiddenError } from './errors/PageForbiddenError';
import { DragState } from './DragState';
import { StudioCommandBuffer } from './studio/StudioCommandBuffer';
import { DesignerToolbox } from './studio/DesignerToolbox';
import { IRectangle } from 'components/helpers/AnchorPoint';


export class CanvasDesigner {
    canvas:Canvas;
    dropTargetElem:any;
    dropTargetIsContainer:boolean;
    dragElem:any;
    dragElemContainer:any;
    count:number = 0;
    nextId:number = 10000;
    
    refresh(){
        console.log("designer refresh",this.count++)
        this.canvas.update();
    }

    setDropTarget(elem:any,isContainer:boolean){
        if (this.dropTargetElem != elem || isContainer != this.dropTargetIsContainer){
            this.dropTargetElem = elem;
            this.dropTargetIsContainer = isContainer;
            this.refresh();
        }
    }
    
    findElem(id:string,children:any[]):number{
        if (!children) return -1;
        for(let i =0 ; i < children.length; i++){
            let child = children[i];
            if (child.$id == id) return i;
        }
        return -1;
    }

   
}


export type CanvasStackPosition = 'overlay' | 'top';


export class Canvas {
    private _data:any;
    private static _nextId:number = 1;
    
    id:string;
    closeDialogBox:(result:DialogResult) => void;
    updateComponent:() => void;
    initialized:boolean;
    loadError:string;
    pendingFocusField:FieldRef;
    pendingFocusRow:any;
    layer:'layout' | 'workspace' | 'workspace-tab' | 'stack' | 'dialog' | 'lookup' | 'embedded' | 'sidebar' | 'bot-message' | 'dialog-stack' | 'popup';
    cacheStrategy: null | 'nocache' |'optimistic';

    layerZIndex:number;
    title:string;
    subtitle:string;
    icon:string;
    width:string;
    height:string;
    scrollable:boolean;
    styles:string;
    pageType : 'help' | null;
    coveredTitle:string;
    noPadding:boolean;
    asWidget:boolean;
    
    parent:Canvas;
    handleRecordChanged:RecordChangedHandler;
    app:Application;

    sidebarState:SidebarState;
    navbarState:NavbarState;
    stackChild: () => any;
    stackPosition:CanvasStackPosition;
    stackPositionOverride:CanvasStackPosition;
    stackStyle:string;
    showClose:boolean;
    stackOverlayOffsetX:number;

    getContainerWidth:() => number;

    sidebarContent:() => any;

    activePopup: {
        render:(options:{onResize:()=> void}) => any,
        rect:IRectangle
    };

    activeContentId:any;
    tabContent:() => any;
    recordBrowse:RecordBrowse;
    defaultRecord:string;
    enterKeyAction:string;
    schema:IRecordSchema;
    $$meta:RecordMeta;
    launchParams:IPageLaunchParams;
    pageId:string;
    pageInfo: {
        page:string;
        version:string;
        draft_id:string;
    };
    actions:{[name:string]:IActionDefinition};
    displayContent:any;
    htmlContent:string;
    blockState:{[name:string]:any};
    designer:CanvasDesigner;

    autoRefreshSettings:AutoRefreshSettings;
    visibiltyContainer:IVisibilityContainer;
    animationComplete:boolean;

    dataBroadcast:Broadcast;
    hardRefreshKey = 0;
    botInfo:IBotInfo;
    icons:{[name:string]:{viewBox:string,path:string}} = {};
    contextMenuId:string;
    closed:boolean;
    loadedFromCache:boolean;
    renderDisabled:boolean;
    dragState:DragState;
    designerToolbox:DesignerToolbox;
    designFocus:string;
    activeDesignElement:string;
    designerOpen:boolean;
    studioCommandBuffer:StudioCommandBuffer;
    studioSource:string;
    
    trackUnsavedChanges:boolean;
    hasUnsavedChanges:boolean;
    slotContent:{[name:string]:any} = {};
    autofillFields:{input:HTMLInputElement,field:FieldRef}[];

    renderDesignSlot:(event:RenderEvent,section:string) => any;
    s4s:any;
    
    constructor(parent:Canvas){
        this.parent = parent;
        if (parent){
            this.app = parent.app
        }
        else {
            this.app = Application.instance;
        }
        let appRecord = new AppDataRecord(this.app.appData);
        this._data = {$$meta:new RecordMeta(),app:appRecord};
        this.$$meta = new RecordMeta();
        this.sidebarState = new SidebarState();
        this.navbarState = new NavbarState();
        this.blockState = {};
        this.designer = new CanvasDesigner();
        this.designer.canvas = this;
        this.dataBroadcast = new Broadcast();
        this.id = (Canvas._nextId++).toString();
    }

    loadSettings(settings:CanvasSettings){
        this.stackOverlayOffsetX = settings.overlayWidth || 280;
    }

    setSchema(schema:IRecordSchema){
        this.schema = schema;
        let meta = RecordMeta.forRecord(this._data);
        meta.schema = schema;
        for(let key in this._data){
            let value = this._data[key];
            if (value && value.$$meta){
                value.$$meta.schema = Schema.getRecordSchema(this,"@" + key);
            }
        }
    }

    get containerStyle():string {
        return this.layer;
    }

    disconnect(){
        this.updateComponent = null;
        this.app.cart.disconnect(this as any);
    }

    registerAutoFillField(field:FieldRef,input:HTMLInputElement){
        this.autofillFields = this.autofillFields || [];
        this.autofillFields.push({field,input});
    }

    unregisterAutoFillField(input:HTMLInputElement){
        let current = this.autofillFields;
        if (!current) return;
        this.autofillFields = [];
        for(let i = 0; i < current.length;i++){
            let item = current[i];
            if (item.input != input){
                this.autofillFields.push(item);
            }
        }
    }
    getIsVisible():boolean {
        if (this.stackChild) return false;
        let target:Canvas = this;
        while(target){
            if (target.visibiltyContainer && target.visibiltyContainer.hidden){
                return false;
            }
            target = target.parent;
        }
        return true;
    }

    useDragState(){
        if (!this.dragState){
            this.dragState = new DragState(this.app.studioBroadcast);
        }
    }

    onChildLoaded = (childCanvas:Canvas) => {
        if (childCanvas.layer == "stack"){
            if (childCanvas.pageType == "help"){
                this.stackStyle = "help";
                this.update();
            }
            else if (this.stackStyle){
                this.stackStyle = null;
                this.update();
            }
        }
    }

    getSettings():CanvasSettings {
        return this.app.canvasSettingsCache.getSettings(this.launchParams.name);
    }
    
    setAutoRefresh(frequencySeconds:number){
        this.autoRefreshSettings = new AutoRefreshSettings(this,frequencySeconds);
    }

    loaded(){
        this.initialized = true;
        let onCartChange = this.actions["on-cart-changed"];
      
        if (onCartChange){
             let action = new ActionRef(this,"on-cart-changed",null);
             this.app.cart.connect(this as any,()=> {
                 this.triggerAction(action);
             })
        }
    }

    openPopup(options:{target?:HTMLElement,rect?:IRectangle,render:(options:{onResize:()=> void}) => any}){
        let rect:IRectangle;
        if (options.rect){
            rect = options.rect;
        }
        else if(options.target){
            let bounding = options.target.getBoundingClientRect();
            rect = {
                top:bounding.top,
                bottom:bounding.bottom,
                left:bounding.left,
                right:bounding.right
            }
        }
        this.activePopup = {
            rect,
            render:options.render
        };
        this.update();
    }

    closePopup(){
        this.activePopup = null;
        this.update();
    }

    getHelpTopic(name:string){
        let helpTopics = (this.schema as any).helpTopics;
        if (helpTopics){
            return helpTopics[name];
        }

    }

    closeMenu(clickTarget:HTMLElement){
        let menuElement = clickTarget.closest('[data-menu-id]');
        if (menuElement){
            let menuId = menuElement.getAttribute("data-menu-id");
            this.app.menuManager.close(menuId);
        }
    }

    resetData(options?:{preserveInputParams:boolean}){
        let input;
        if (options && options.preserveInputParams){
            input = {};
            for(let key in this.schema.fields){
                let f = this.schema.fields[key];
                if (f.input){
                    input[f.name.substring(1)] = this._data[f.name.substring(1)];
                }
            }
        }
        let appRecord = new AppDataRecord(this.app.appData);
        appRecord.$$meta.schema = Schema.getRecordSchema(this,"@app");
        this._data = {$$meta:new RecordMeta(),app:appRecord,...input};
        this.$$meta = new RecordMeta();
    
        this.initialized = false;
    }

    async restart(options?:IEventOptions){
       // this.resetData({preserveInputParams:true});
        let begin =  new ActionRef(this,"begin",{});
        await this.triggerAction(begin,options);
    }

    getIsStackCovered():boolean {
        let canvas:Canvas= this;
        while(canvas){
            if (canvas.stackChild) return true;
            if (Stack.isStackTarget(canvas)) return false;
            canvas = canvas.parent;
        }
        return false;
    }

    loadStartValues(){
        let appRecord = new AppDataRecord(this.app.appData);
        // todo: preserve current lineitems
        this._data = {$$meta:new RecordMeta(),app:appRecord};
        // find / set current line items
        this.$$meta = new RecordMeta();
    }

    getCurrentRowKeys(){
        let schema = this.schema;
        let currentRowKeys = {};
        if (schema){
            for(let key in schema.fields){
                let f = schema.fields[key];
                if (f.type == "collection"){
                    let currentRow = Collection.getCurrentRow(this,key);
                    if (currentRow){
                        let rowKey = Collection.getRowKey(currentRow,f.recordSchema);
                        if (rowKey){
                            currentRowKeys[key] = rowKey;
                        }
                    }
                }
            }
        }
        return currentRowKeys;
    }

    restoreCurrentRowKeys(rowKeys:any){
        let schema = this.schema;
        for(let key in schema.fields){
            let f = schema.fields[key];
            if (f.type == "collection"){
                let rowKey = rowKeys[key];
                if (rowKey){
                    let row = Collection.findRowByKey(this,key,rowKey);
                    if (row){
                        Collection.setCurrentRow(this,key,row);
                    }
                }
            }
        }
    }

    setData(data:any){
        if (data){
            for(let key in data){
                this._data[key] = data[key];
            }
        }
    }

    update(){
        if (this.updateComponent){
            this.updateComponent();
        }
    }

    setRecordValueNoUpdate(field:FieldRef,value){
        if (field.record){
            let name = field.name;
            if (name[0] == '@'){
                this.setRecordProp(field.record,name.substr(1),value);
            }
            else {
                this.setRecordProp(field.record,name,value);
                if (field.recordName == "@app"){
                    this.appValueUpdated(name,value);
                }
            }
           
        }
    }

    private appValueUpdated(name:string,value:any){
        for(let i = 0; i < this.app.activeScreens.length;i++){
            let c = this.app.activeScreens[i].canvas;
            
            if (c.initialized){
                let field = c.getFieldRef("@app." + name,null);        
                if (field != null){
                    if (field.def.onValueChanged){
                        let actionRef = new ActionRef(c,field.def.onValueChanged,field.scope);
                        field.clearError();
                        actionRef.trigger({value});
                    }
                }
            }
        }
    }

    private setRecordProp(record:any,name:string,value:any){
        if (record.$$set){
            record.$$set(name,value);
        }
        else {
            record[name] = value;
        }
    }
    
    async setValue(field:FieldRef,value:any,onValueChanged?:ActionRef){
        if (field.value != value){
            this.hasUnsavedChanges = true;
        }
        if (field.record){
            let name = field.name;
            if (name[0] == '@'){
                this.setRecordProp(field.record,name.substring(1),value);
            }
            else {
                this.setRecordProp(field.record,name,value);
            }
        }
        if (onValueChanged){
            field.clearError();
            await onValueChanged.trigger({value});
        }
        else {
            let onValueChangedAction = field.onValueChanged;
            if (onValueChangedAction){
                let actionRef = new ActionRef(this,onValueChangedAction,field.scope);
                field.clearError();
                await actionRef.trigger({value});
            }
        }
        this.update();
    }

    pendingRestart:boolean;
    closeStack(){
        this.stackChild = null;
        this.activePopup = null;
        this.update();
    }

    
    async recordChanged(recordEvent:IRecordChangedEvent){
        
        let canvas:Canvas = this;
        let stack:Canvas[] = [];
        while(canvas){
            if (canvas.handleRecordChanged){
                stack.push(canvas);
                canvas = canvas.parent;
            }
            else {
                break;
            }
        }

        while (stack.length){
            canvas = stack.pop();
            await canvas.handleRecordChanged(recordEvent);    
        }
    }

  
    async triggerAction(actionRef:ActionRef,options:IEventOptions = {}):Promise<IActionResult>{
        if (!actionRef) return;

        if (this.autofillFields){
            for(let i = 0; i < this.autofillFields.length;i++){
                let item = this.autofillFields[i];
                let value = item.input.value;
                if (value != item.field.value){
                    item.field.setValueNoUpdate(value);
                }
            }
        }
        
        var event = new ActionEvent(this,options);
        event.options.actionName = actionRef.name;
        let action = this.getActionHandler(actionRef);
        return this.executeActionHandler(actionRef.name,action,options);
    }

    async executeActionHandler(actionName:string,action:ActionHandler,options:IEventOptions = {}):Promise<IActionResult>{
        if (!action) return {continue:true};
        var event = new ActionEvent(this,options);
        event.options.actionName = actionName;
       
        this.app.spinner.show();
        if (this.studioCommandBuffer && actionName != "studio-content-updated"){
            console.log("flushing command buffer");
            await this.studioCommandBuffer.send();
        }
        
        try {
            await action(event);
            this.app.spinner.hide();
        }
        catch(err: any){
            if (actionName == "begin" && err instanceof PageForbiddenError && this.layer == "embedded"){
                // suppress error display
                this.renderDisabled = true;
                if (!options.noUpdate){
                    this.update();
                }
                this.app.spinner.hide();
                throw err;
            }
            this.app.spinner.kill();
            this.update();
            if (err != "stopped"){
                let error:ICanvasError;
                if (err.$$error){
                    error = err;
                }
                else {
                    error = {
                        messages:[err.toString()],
                        $$error:true
                    }
                }
                if (options.title){
                    error.validationErrors = error.validationErrors || [];
                    error.validationErrors.push({message:options.title});
                }
                let errorDialog = this.app.renderError(this,error);
                ErrorDialog.show(this.app,errorDialog);
                //Dialog.open(this,errorDialog,null,"dialog"); 
            }
            throw "stopped";
            //return {continue:false};
        }
        if (!options.noUpdate){
            this.update();
        }
        return {continue:true};
    }

    handleEnterKey(scope:IEventScope){
        if (ErrorDialog.isVisible) return;
        
        let actionName = (scope && scope.enterKeyAction) ? scope.enterKeyAction : this.enterKeyAction;
        if (!actionName) return;
        let actionRef = new ActionRef(this,actionName,null);
        this.triggerAction(actionRef);
    }
   
    getRecord(name:string,scope:any):any {
        if (!name) return null;
        if (scope){
            let record = scope[name];
            if (record) return record;
        }
        return this._data[name.substring(1)];
    }

    setRecord(name:string,value:any){
        if (!name) return null;
        this._data[name.substring(1)] = value;
    }
    getFieldValue(field:string,scope?:any):any {
        let fieldRef = this.getFieldRef(field,scope);
        if (!fieldRef) return null;
        return fieldRef.value;
    }

    getFieldRef(field:string,scope:any):FieldRef {
        let record:any;
        let recordName:string;
        if (field){
            if (field[0] == '@'){

                let i = field.indexOf('.');
                if (i != -1){
                    let target = field.substr(0,i);
                    let f = field.substr(i + 1);
                    recordName = target;
                    field = f;
                }
                else {
                    field = field;
                }
                if (recordName){
                    let i = recordName.indexOf('[');
                    if (i != -1){
                        let indexer = parseInt(recordName.substring(i + 1,recordName.length - 1),10);
                        recordName = recordName.substr(0,i);
                        let collection = this._data[recordName.substr(1)];
                        if (collection){
                            return new FieldRef(this,scope,collection[indexer],field,recordName);
                        }
                        // invalid collection
                        return new FieldRef(this,scope,null,field,recordName);
                    }
                }
            }
            else if (this.defaultRecord){
                recordName = this.defaultRecord;
            }
            
            if (recordName){
                if (scope){
                    record = scope[recordName];
                    if (!record){
                        record = this._data[recordName.substr(1)];
                    }
                }
                else {
                    record = this._data[recordName.substr(1)];
                }
                if (Array.isArray(record)){
                    record = Collection.getCurrentRow(this,recordName);
                }
            }
            else {
                record = this._data;
            }
        }
        let fieldRef = new FieldRef(this,scope,record,field,recordName);
        return fieldRef;
    }

    getCollectionRef(value:string | {rows:any[],schema:IRecordSchema,name:string},scope:any):CollectionRef {
        if (!value) return null;
        if (typeof(value) === "string"){
        
            let rows:any[];
            if (value[0] == "@"){
                rows = this._data[value.substring(1)];
            }
            let schema = Schema.getRecordSchema(this,value);
            return new CollectionRef(this,scope,rows,value,schema);
        }
        return new CollectionRef(this,scope,value.rows,value.name,value.schema);
    }

    expressionCache = {};

    getValue(expr:string,options:IEventOptions):any{
        return Evaluator.evaluate({canvas:this,scope:options.scope,eventValue:options.value,returnValue:options.returnValue},expr);
    }

    getActionHandler(actionRef:ActionRef):ActionHandler {     

        if (actionRef.name == "begin"){
            return this.startAction;
        }

        let statements:any[];
        if (actionRef.statements){
            statements = actionRef.statements;
        }
        else {
            let action = this.actions[actionRef.name];
            if (action){
                statements = action.statements;
            }
        }

        if (statements){
            return async(event:ActionEvent) => {
                await executeCommands(event.screen,statements);
            }
        }
    }

    startAction =  async({screen}:ActionEvent) =>{
        let params = {};
        let canvas = screen.canvas;
        let callType: IRequestCallType;
        if (canvas.initialized){ // is restart
            callType = "restart";
            for(let key in canvas.schema.fields){
                let f = canvas.schema.fields[key];
                if (f.input){
                    if (f.isRange || f.type == "record" || f.type == "collection"){
                        params[f.name] = canvas.getRecord(f.name,null);
                    }
                    else {
                        params[f.name] = canvas.getFieldValue(key,{});
                    }
                }
                
            }
            if (screen.eventOptions.triggerSearch){
                params["@trigger_search"] = true;
            }
        }
        else {
            callType = "start";
            let pageProps = this.launchParams.props;
            for(let key in pageProps){
                params[key] = pageProps[key];
            }
        }
        let appValues = canvas.app.appData;
        if (appValues){
            for(let key in appValues){
                params["@app." + key] = appValues[key];
            }
        }
        let res = await Request.callBatch(screen,callType,"begin",params);
       
    }


    createScriptCallParameters(parameters:{name:string,value:string}[],eventOptions:IEventOptions):any {
        let params = {};
        let fields = this.schema.fields;
        if (parameters){
            for(let i = 0; i < parameters.length;i++){
                let p = parameters[i];
                let field = fields[p.name.toLowerCase()];
                
                if (field && field.isRange){
                    params[field.name] = this.data[field.name.substring(1)];
                }
                else {
                    params[p.name] = this.getValue(p.value,eventOptions);
                }
        
                params[p.name] = this.getValue(p.value,eventOptions);
            }
        }
        return params;
    }

    getSearch(collection:string){
        var collectionRef = this.getCollectionRef(collection,null);
        if (collectionRef){

        }
    }

    createLaunchParameters(parameters:IScreenParameter[],eventOptions:IEventOptions):any{

        let params = {};
        if (parameters){
            for(let i = 0; i < parameters.length;i++){
                let p = parameters[i];
                let propName = p.name;
                if (propName){
                    params[propName] = this.getValue(p.value,eventOptions);
                }
                
            }
        }
        return params;
    }

    get data():any {
        return this._data;
    }

    connectDataListener(listener:React.Component<{},{}>,varName:string,callback:(action?:string,data?:any)=> void){
        this.dataBroadcast.connect(listener,varName,(action,data) => callback(action,data));
    }

    disconnectDataListener(listener:React.Component<{},{}>){
        this.dataBroadcast.disconnect(listener);
    }

    viewSource(){
        let page:IPageLaunchParams = {
            name:"dev/ViewSource",
            props:{
                "@page_id":this.launchParams.name
            }
        }
        let content = <PageContainer page={page} />
        Dialog.open(this,content,null,"dialog");
    }
}



