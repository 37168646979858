import { ScreenRef } from "../core/ActionEvent";
import {Application, IServiceResponse, Request, Schema} from '../core';
export class LISAApi {

    static async doPostRequest(path:string,body:any):Promise<any>{
        let app = Application.instance;
        let url = app.qualifyUri(path);
        let info:any =  {
            method:"POST",
            credentials:"include",
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(body,LISAApi.replacer)
        };
        let res = await fetch(url,info);
        let contentType = res.headers.get("content-type");
        if (contentType == "text/html"){
            let text = await res.text();
            let htmlRes:IServiceResponse = {
                actions:{},
                schema:{
                    name:"",
                    fields:{},
                    projection:[]
                },
                run:null,
                configure:null,
                success:true,
                errorTable:null,
                retryAfterWarnings:false,
                debug:null,
                error:null,
                code:null,
                codeErrors:null,
                codeHTML:null,
                codeSource:null,
                returnValue:null,
                pageInfo:{
                    page:"",
                    version:"",
                    draft_id:""
                },
                contentHTML:text
            };
            return htmlRes;
        }
        let json = await res.json();
        if (json && json.debug){
            let studioWindow = Application.instance.studioWindow;
            if (studioWindow){
                studioWindow.postMessage({action:"addDebugEntry",entry:json.debug},"*");
            }
        }
        return json;
    }

    static replacer(key, value) {
        if (key[0] == "$" && key[1] == "$") return undefined;
        return value;
    }
      
    

    
}





